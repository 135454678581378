import React from "react";
import axios from "axios";
// import bookingService from "../services/bookingService";

const BookingSuccess = ({ bookedDetails }) => {
  // const [booking, setBooking] = useState(null);

  console.log(bookedDetails, "here-details");

  // useEffect(() => {
  //   const fetchBookingDetails = async () => {
  //     const headers = {
  //       "x-orderId": bookedDetails.order_id,
  //       "x-signatureId": bookedDetails.razorpay_signature,
  //       "x-paymentId": bookedDetails.razorpay_payment_id,
  //     };

  //     try {
  //       const response = await bookingService.getBookingById(
  //         bookedDetails?.bookingId,
  //         headers
  //       );
  //       setBooking(response);
  //     } catch (error) {
  //       console.error("Error fetching booking details", error);
  //     }
  //   };

  //   if (bookedDetails?.bookingId) {
  //     fetchBookingDetails();
  //   }
  // }, [bookedDetails]);

  const downloadPDF = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/booking/${bookedDetails.bookingId}/pdf`,
        {
          responseType: "blob", // Important for binary response
          headers: {
            "x-orderId": bookedDetails.order_id,
            "x-signatureId": bookedDetails.razorpay_signature,
            "x-paymentId": bookedDetails.razorpay_payment_id,
          },
        }
      );

      // Create a URL for the PDF blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `booking-${bookedDetails.bookingId}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading PDF", error);
    }
  };

  if (!bookedDetails.bookedInfo) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-2xl">
        <h1 className="text-2xl font-bold text-green-600 mb-4">
          Booking Successful!
        </h1>
        <h2 className="text-lg font-semibold text-gray-800 mb-4">
          Booking Details
        </h2>
        <div className="grid grid-cols-1 gap-4 text-gray-600">
          <p>
            <strong>Booking ID:</strong> {bookedDetails.bookedInfo._id}
          </p>
          <p>
            <strong>Customer Name:</strong>{" "}
            {bookedDetails.bookedInfo.bookedCustomer.fullName}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {bookedDetails.bookedInfo.bookedCustomer.email}
          </p>
          <p>
            <strong>Phone Number:</strong>{" "}
            {bookedDetails.bookedInfo.bookedCustomer.phoneNumber}
          </p>
          <p>
            <strong>Bus:</strong> {bookedDetails.bookedInfo.bus.name}
          </p>
          <p>
            <strong>Trip:</strong> {bookedDetails.bookedInfo.trip.trip_name}
          </p>
          <p>
            <strong>Seat Numbers:</strong>{" "}
            {bookedDetails.bookedInfo.seatNumbers.join(", ")}
          </p>
          <p>
            <strong>Total Amount:</strong> ₹
            {bookedDetails.bookedInfo.totalAmount}
          </p>
          <p>
            <strong>Pickup Location:</strong>{" "}
            {bookedDetails.bookedInfo.bookedBusStop}
          </p>
          <p>
            <strong>Destination:</strong>{" "}
            {bookedDetails.bookedInfo.trip.destination}
          </p>
        </div>

        <h3 className="text-lg font-semibold text-gray-800 mt-6 mb-2">
          Travellers
        </h3>
        <ul className="list-disc pl-5 text-gray-600">
          {bookedDetails.bookedInfo.travellers.map((traveller, index) => (
            <li key={index} className="mb-1">
              {traveller.fullName} (Age: {traveller.age})
            </li>
          ))}
        </ul>

        <button
          onClick={downloadPDF}
          className="mt-6 w-full py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition-all duration-200"
        >
          Download Booking PDF
        </button>
      </div>
    </div>
  );
};

export default BookingSuccess;
