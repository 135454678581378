"use client";
import React, { useEffect, useState } from "react";
import tripService from "../services/tripService";
import bustopService from "../services/bustopService";
import { useNavigate } from "react-router-dom";
import ayyapanImg from "../assets/ayyapan.png";

const TripSearchInput = ({ trips }) => {
  const [tripId, setTripId] = useState({});
  const [pickupLocations, setPickupLocations] = useState([]);
  const [pickupLocationId, setPickupLocationId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (trips.length > 0) {
      setTripId(trips[0]._id);
      getPickupLocation();
    }
  }, [trips]);

  const getPickupLocation = async () => {
    const tripPickUpLocation = await bustopService.getBustops();

    if (tripPickUpLocation) {
      setPickupLocations(tripPickUpLocation[0].busStops);
      setPickupLocationId(tripPickUpLocation[0].busStops[0]._id);
    }
  };

  let price =
    pickupLocations?.length > 0 && pickupLocationId
      ? pickupLocations.find((cur) => cur._id === pickupLocationId).price
      : "";

  //   const getPickUpLocation = (tripId) => {
  //     const price = pickupLocations.find((cur) => cur._id === tripId);
  //     if (price.price) {
  //       return price.price;
  //     }
  //     return "";
  //   };

  const onBookingClick = async () => {
    navigate(`/booking/${tripId}?pickupstopId=${pickupLocationId}`);
  };

  return (
    // py-16 px-8
    <>
      {pickupLocations?.length > 0 && trips?.length > 0 ? (
        <div className="max-w-screen-xl mx-auto -translate-y-16">
          <div className="flex items-center -mt-6 ml-2">
            <h2 className="text-2xl font-bold text-white mb-3 text-nowrap">
              Book Your <span className="text-[#a2e53f]">Sabarimala Trip</span>{" "}
            </h2>
            <img src={ayyapanImg} alt="Ayyapan" style={{ height: 100 }} />
          </div>

          <div className="bg-white  relative z-50 shadow-md rounded-lg grid grid-cols-1 md:grid-cols-4 gap-4 overflow-hidden">
            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              <select
                name="tripname"
                value={tripId}
                className="h-24 border-none focus:ring-0 px-8 w-full"
                onChange={(e) => {
                  setTripId(e.target.value);
                  // getPickupLocation(e.target.value);
                }}
              >
                {trips.map((cur) => (
                  <option value={cur._id}>{cur.trip_name}</option>
                ))}
              </select>
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>
            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              <select
                name="pickup"
                className="h-24 border-none focus:ring-0 px-8 w-full"
                onChange={(e) => setPickupLocationId(e.target.value)}
              >
                {pickupLocations.map((cur) => (
                  <option value={cur._id}>{cur.busStopName}</option>
                ))}
              </select>
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>

            <div className="flex items-center justify-between w-full border-b md:border-b-0 border-gray-300 pb-2 mb-2 md:mb-0">
              {/* <select
                name="location"
                id="location"
                className="h-24 border-none focus:ring-0 px-8 w-full"
              >
                <option value="dubai">Dubai, UAE</option>
              </select> */}
              <input
                type="text"
                placeholder="Try Mustang..."
                className="h-24 border-none focus:ring-0 px-8 w-full"
                // value={getPricing(pickupLocationId)}
                value={`₹${price} per Person`}
                disabled
              />
              <div className="h-[60%] bg-gray-200 w-[1px] ml-4 hidden md:block"></div>
            </div>

            <div className="w-full">
              <button
                type="button"
                className="text-dark bg-[#a2e53f] focus:outline-none w-full h-24 text-lg font-semibold"
                onClick={onBookingClick}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <h2>Ooops! Cannot Book Now.. Try Again</h2>
      )}
    </>
  );
};

export default TripSearchInput;
