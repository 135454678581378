import React from "react";
import { Link } from "react-router-dom";
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import greenLogo from "../../assets/white_logo.png";
import isoLogo from "../../assets/iso-logo.png";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
        {/* Logo and Contact Section */}
        <div className="flex flex-col items-start">
          <div className="flex gap-4 mb-6">
            <img src={greenLogo} style={{ width: 150 }} alt="Logo" />
            <img
              src={isoLogo}
              style={{ width: 200, objectFit: "contain" }}
              alt="ISO Logo"
              className="mr-4"
            />
          </div>
          <div className="space-y-3 mb-4">
            <p className="text-sm flex items-center">
              <FaEnvelope className="mr-2" />
              <a
                href="mailto:vismayaexpresslines@gmail.com"
                className="hover:underline"
              >
                vismayaexpresslines@gmail.com
              </a>
            </p>
            <p className="text-sm flex items-center">
              <FaPhoneAlt className="mr-2" />
              <a href="tel:+919846090098" className="hover:underline">
                +91 98460 90098
              </a>
            </p>
            <p className="text-sm flex items-center">
              <FaMapMarkerAlt className="mr-2" />
              Address: Vismaya Greenlines Private Limited Guruvayoor, Thrissur,
              Kerala 680505
            </p>
          </div>
          {/* Social Media Links */}
          <div className="flex gap-6 mt-4">
            <a
              href="https://www.facebook.com/yourpage"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-500"
            >
              <FaFacebookF size={30} />
            </a>
            <a
              href="https://www.instagram.com/yourpage"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-pink-500"
            >
              <FaInstagram size={30} />
            </a>
            <a
              href="https://www.twitter.com/yourpage"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400"
            >
              <FaTwitter size={30} />
            </a>
          </div>
        </div>

        {/* Navigation Links Section */}
        <div className="flex flex-col md:items-center">
          <span className="text-lg font-semibold mb-4 sm:-ml-20">
            Quick Links
          </span>
          <div className="flex flex-col space-y-2 text-sm">
            <Link to="/about" className="hover:underline">
              About Us
            </Link>
            <Link to="/contact" className="hover:underline">
              Contact
            </Link>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
            <Link to="/terms&conditions" className="hover:underline">
              Terms & Conditions
            </Link>
            <Link to="/cancellation-refundpolicy" className="hover:underline">
              Cancellation & Refund Policy
            </Link>
          </div>
        </div>

        {/* Company Info Section */}
        <div className="text-sm">
          <h4 className="text-lg font-semibold mb-4">Company Information</h4>
          <p className="leading-relaxed mb-6">
            Vismaya Greenlines Private Limited is committed to providing
            excellent services. Our focus is on customer satisfaction and
            environmental sustainability. We ensure that our services adhere to
            the highest standards.
          </p>
        </div>
      </div>

      <div className="text-center mt-8 border-t border-gray-700 pt-4 text-xs">
        © {new Date().getFullYear()} Vismaya Greenlines Private Limited. All
        rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
