// import React from "react";

// // Helper function to split seats by rows and positions
// const getSeatsByRow = (seats) => {
//   const rows = {};
//   seats.forEach((seat) => {
//     const row = seat.position.split("-")[1]; // Extract the row number (1, 2, 3)
//     if (!rows[row]) rows[row] = { left: [], right: [] };
//     if (seat.position.startsWith("left")) {
//       rows[row].left.push(seat);
//     } else {
//       rows[row].right.push(seat);
//     }
//   });
//   return rows;
// };

// const SeatSelector = ({
//   selectedSeats,
//   handleSeatSelection,
//   seats,
//   bookedSeats = [],
// }) => {
//   const seatRows = getSeatsByRow(seats);

//   return (
//     <div className="container mx-auto py-8">
//       <h2 className="text-2xl font-bold mb-6 text-center">Select Your Seat</h2>

//       <div className="flex justify-center">
//         <div className="flex flex-row">
//           {/* Left Seats */}
//           <div className="flex flex-col">
//             {Object.keys(seatRows).map((rowKey) => (
//               <div key={rowKey} className="flex">
//                 {seatRows[rowKey].left.map((seat) => (
//                   <button
//                     key={seat._id}
//                     className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
//                       selectedSeats.includes(seat.seatNumber)
//                         ? "bg-green-500 text-white"
//                         : bookedSeats.includes(seat.seatNumber)
//                         ? "bg-red-600 text-white"
//                         : "bg-gray-300 text-gray-800"
//                     }`}
//                     onClick={() => {
//                       !bookedSeats.includes(seat.seatNumber) &&
//                         handleSeatSelection(seat.seatNumber);
//                     }}
//                   >
//                     {seat.seatNumber}
//                   </button>
//                 ))}
//               </div>
//             ))}
//           </div>

//           {/* Aisle */}
//           <div className="flex flex-col justify-center items-center mx-2">
//             <div className="w-2 h-full bg-gray-200 rounded"></div> {/* Aisle */}
//           </div>

//           {/* Right Seats */}
//           <div className="flex flex-col">
//             {Object.keys(seatRows).map((rowKey) => (
//               <div key={rowKey} className="flex">
//                 {seatRows[rowKey].right.map((seat) => (
//                   <button
//                     key={seat._id}
//                     className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
//                       selectedSeats.includes(seat.seatNumber)
//                         ? "bg-green-500 text-white"
//                         : bookedSeats.includes(seat.seatNumber)
//                         ? "bg-red-600 text-white"
//                         : "bg-gray-300 text-gray-800"
//                     }`}
//                     onClick={() => {
//                       !bookedSeats.includes(seat.seatNumber) &&
//                         handleSeatSelection(seat.seatNumber);
//                     }}
//                   >
//                     {seat.seatNumber}
//                   </button>
//                 ))}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* Booking Summary */}
//       <div className="mt-8 text-center">
//         <h3 className="text-lg font-semibold mb-4">Selected Seats:</h3>
//         <div className="space-x-2">
//           {selectedSeats.length > 0 ? (
//             selectedSeats.map((seat) => (
//               <span
//                 key={seat}
//                 className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full text-xs"
//               >
//                 {seat}
//               </span>
//             ))
//           ) : (
//             <p className="text-gray-600">No seats selected</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SeatSelector;

// import React from "react";

// // Helper function to split seats by rows and positions
// const getSeatsByRow = (seats) => {
//   const rows = {};
//   seats.forEach((seat) => {
//     const row = parseInt(seat.position.split("-")[1], 10); // Extract the row number as an integer
//     if (!rows[row]) rows[row] = { left: [], right: [] };
//     if (seat.position.startsWith("left")) {
//       rows[row].left.push(seat);
//     } else {
//       rows[row].right.push(seat);
//     }
//   });
//   return rows;
// };

// const SeatSelector = ({
//   selectedSeats,
//   handleSeatSelection,
//   seats,
//   bookedSeats = [],
// }) => {
//   const seatRows = getSeatsByRow(seats);

//   // Find the minimum and maximum row numbers
//   const rowNumbers = Object.keys(seatRows).map(Number);
//   const minRow = 1; // Always start from row 1
//   const maxRow = Math.max(...rowNumbers);

//   return (
//     <div className="container mx-auto py-8">
//       <h2 className="text-2xl font-bold mb-6 text-center">Select Your Seat</h2>

//       <div className="flex justify-center">
//         <div className="flex flex-row">
//           {/* Left Seats */}
//           <div className="flex flex-col">
//             {[...Array(maxRow)].map((_, index) => {
//               const rowIndex = index + 1;
//               const row = seatRows[rowIndex];

//               return (
//                 <div key={rowIndex} className="flex">
//                   {row && row.left.length > 0 ? (
//                     row.left.map((seat) => (
//                       <button
//                         key={seat._id}
//                         className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
//                           selectedSeats.includes(seat.seatNumber)
//                             ? "bg-green-500 text-white"
//                             : bookedSeats.includes(seat.seatNumber)
//                             ? "bg-red-600 text-white"
//                             : "bg-gray-300 text-gray-800"
//                         }`}
//                         onClick={() => {
//                           !bookedSeats.includes(seat.seatNumber) &&
//                             handleSeatSelection(seat.seatNumber);
//                         }}
//                       >
//                         {seat.seatNumber}
//                       </button>
//                     ))
//                   ) : (
//                     <div className="w-10 h-10 m-1"></div> // Empty space for missing row
//                   )}
//                 </div>
//               );
//             })}
//           </div>

//           {/* Aisle */}
//           <div className="flex flex-col justify-center items-center mx-2">
//             <div className="w-1 h-full  rounded"></div>
//           </div>

//           {/* Right Seats */}
//           <div className="flex flex-col">
//             {[...Array(maxRow)].map((_, index) => {
//               const rowIndex = index + 1;
//               const row = seatRows[rowIndex];

//               return (
//                 <div key={rowIndex} className="flex">
//                   {row && row.right.length > 0 ? (
//                     row.right.map((seat) => (
//                       <button
//                         key={seat._id}
//                         className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
//                           selectedSeats.includes(seat.seatNumber)
//                             ? "bg-green-500 text-white"
//                             : bookedSeats.includes(seat.seatNumber)
//                             ? "bg-red-600 text-white"
//                             : "bg-gray-300 text-gray-800"
//                         }`}
//                         onClick={() => {
//                           !bookedSeats.includes(seat.seatNumber) &&
//                             handleSeatSelection(seat.seatNumber);
//                         }}
//                       >
//                         {seat.seatNumber}
//                       </button>
//                     ))
//                   ) : (
//                     <div className="w-10 h-10 m-1"></div> // Empty space for missing row
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>

//       {/* Booking Summary */}
//       <div className="mt-8 text-center">
//         <h3 className="text-lg font-semibold mb-4">Selected Seats:</h3>
//         <div className="space-x-2">
//           {selectedSeats.length > 0 ? (
//             selectedSeats.map((seat) => (
//               <span
//                 key={seat}
//                 className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full text-xs"
//               >
//                 {seat}
//               </span>
//             ))
//           ) : (
//             <p className="text-gray-600">No seats selected</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SeatSelector;

import React from "react";

// Helper function to split seats by rows and positions
const getSeatsByRow = (seats) => {
  const rows = {};
  seats.forEach((seat) => {
    const row = parseInt(seat.position.split("-")[1], 10); // Extract the row number as an integer
    if (!rows[row]) rows[row] = { left: [], right: [] };
    if (seat.position.startsWith("left")) {
      rows[row].left.push(seat);
    } else {
      rows[row].right.push(seat);
    }
  });
  return rows;
};

const SeatSelector = ({
  selectedSeats,
  handleSeatSelection,
  seats,
  bookedSeats = [],
}) => {
  const seatRows = getSeatsByRow(seats);

  // Find the minimum and maximum row numbers
  const rowNumbers = Object.keys(seatRows).map(Number);
  const minRow = 1; // Always start from row 1
  const maxRow = Math.max(...rowNumbers);

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-2xl font-bold mb-6 text-center">Select Your Seat</h2>

      {/* Legend Section */}
      <div className="flex justify-center mb-8">
        <div className="flex space-x-6">
          <div className="flex items-center">
            <div className="w-6 h-6 bg-green-500 rounded-lg mr-2"></div>
            <span className="text-gray-700">Selected Seat</span>
          </div>
          <div className="flex items-center">
            <div className="w-6 h-6 bg-red-600 rounded-lg mr-2"></div>
            <span className="text-gray-700">Booked Seat</span>
          </div>
          <div className="flex items-center">
            <div className="w-6 h-6 bg-gray-300 rounded-lg mr-2"></div>
            <span className="text-gray-700">Available Seat</span>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="flex flex-row">
          {/* Left Seats */}
          <div className="flex flex-col">
            {[...Array(maxRow)].map((_, index) => {
              const rowIndex = index + 1;
              const row = seatRows[rowIndex];

              return (
                <div key={rowIndex} className="flex">
                  {row && row.left.length > 0 ? (
                    row.left.map((seat) => (
                      <button
                        key={seat._id}
                        className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
                          selectedSeats.includes(seat.seatNumber)
                            ? "bg-green-500 text-white"
                            : bookedSeats.includes(seat.seatNumber)
                            ? "bg-red-600 text-white"
                            : "bg-gray-300 text-gray-800"
                        }`}
                        onClick={() => {
                          !bookedSeats.includes(seat.seatNumber) &&
                            handleSeatSelection(seat.seatNumber);
                        }}
                      >
                        {seat.seatNumber}
                      </button>
                    ))
                  ) : (
                    <div className="w-10 h-10 m-1"></div> // Empty space for missing row
                  )}
                </div>
              );
            })}
          </div>

          {/* Aisle */}
          <div className="flex flex-col justify-center items-center mx-2">
            <div className="w-2 h-full bg-gray-200 rounded"></div> {/* Aisle */}
          </div>

          {/* Right Seats */}
          <div className="flex flex-col">
            {[...Array(maxRow)].map((_, index) => {
              const rowIndex = index + 1;
              const row = seatRows[rowIndex];

              return (
                <div key={rowIndex} className="flex">
                  {row && row.right.length > 0 ? (
                    row.right.map((seat) => (
                      <button
                        key={seat._id}
                        className={`w-10 h-10 rounded-lg flex items-center justify-center m-1 text-xs ${
                          selectedSeats.includes(seat.seatNumber)
                            ? "bg-green-500 text-white"
                            : bookedSeats.includes(seat.seatNumber)
                            ? "bg-red-600 text-white"
                            : "bg-gray-300 text-gray-800"
                        }`}
                        onClick={() => {
                          !bookedSeats.includes(seat.seatNumber) &&
                            handleSeatSelection(seat.seatNumber);
                        }}
                      >
                        {seat.seatNumber}
                      </button>
                    ))
                  ) : (
                    <div className="w-10 h-10 m-1"></div> // Empty space for missing row
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Booking Summary */}
      <div className="mt-8 text-center">
        <h3 className="text-lg font-semibold mb-4">Selected Seats:</h3>
        <div className="space-x-2">
          {selectedSeats.length > 0 ? (
            selectedSeats.map((seat) => (
              <span
                key={seat}
                className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full text-xs"
              >
                {seat}
              </span>
            ))
          ) : (
            <p className="text-gray-600">No seats selected</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SeatSelector;
