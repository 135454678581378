import React from "react";
import { useNavigate } from "react-router-dom";

const TripCard = ({ trip }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between space-y-4 border hover:shadow-xl transition-shadow duration-300"
      // onClick={() => {
      //   navigate(`/booking/${trip._id}`);
      // }}
    >
      {/* Trip Name */}
      <div>
        <h2 className="text-xl font-bold text-gray-800">{trip.trip_name}</h2>
      </div>

      {/* Bus Information */}
      <div>
        <h3 className="text-lg text-gray-600">Bus Information</h3>
        <p className="text-sm text-gray-500">
          <strong>Bus Name: </strong>
          <b>{trip.bus.name}</b>
        </p>
      </div>

      {/* Expected Go */}
      <div>
        <h3 className="text-lg text-gray-600">Expected Departure</h3>
        <p className="text-sm text-gray-500">
          <strong>Date: </strong>
          {trip.expected_go.date}
        </p>
        <p className="text-sm text-gray-500">
          <strong>Time: </strong>
          {trip.expected_go.time}
        </p>
      </div>

      {/* Expected Reach */}
      <div>
        <h3 className="text-lg text-gray-600">Expected Arrival</h3>
        <p className="text-sm text-gray-500">
          <strong>Date: </strong>
          {trip.expected_reach.date}
        </p>
        <p className="text-sm text-gray-500">
          <strong>Time: </strong>
          {trip.expected_reach.time}
        </p>
      </div>

      {/* Ongoing Booking */}
      <div className="flex items-center">
        <span
          className={`inline-block px-3 py-1 text-xs font-semibold rounded-full ${
            trip.onGoingBooking
              ? "bg-green-100 text-green-700"
              : "bg-red-100 text-red-700"
          }`}
        >
          {trip.onGoingBooking ? "Ongoing Booking" : "Booking Closed"}
        </span>
      </div>
    </div>
  );
};

export default TripCard;
