import React from "react";
import Button from "./Button";
import image1 from "../assets/image1.jpg";
import image2 from "../assets/image2.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import Layout from "./Layout/Layout";
import TripCard from "./TripCard";
import TripSearchIput from "./TripSearchInput";

const HomeWrapper = ({ trips }) => {
  return (
    <Layout>
      <div className="relative">
        <div
          className="relative h-[60vh] bg-cover bg-no-repeat bg-center"
          style={{
            backgroundImage: `url(${image1})`,
          }}
        >
          {/* Dark Overlay */}
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />

          <div className="absolute -top-10 left-0 w-full h-full flex flex-col justify-center items-start px-4 sm:px-12">
            <h2 className="text-4xl font-bold mb-6 text-[#fff]">
              SWAMI <span className="text-[#a2e53f]">SARANAM</span>
              {/* Book Your <span className="text-[#a2e53f]">Sabarimala Trip</span> */}
            </h2>
            <ul className="text-white text-lg list-disc list-inside space-y-2">
              <li>AC pushback semi sleeper buses</li>
              <li>Experienced guide</li>
              <li>Ontime performance</li>
              <li>Free Onboard water bottles</li>
            </ul>
          </div>
        </div>

        <TripSearchIput trips={trips} />
        {/* <div className="relative z-10 -mt-1 sm:-mt-12 sm:justify-center flex flex-col sm:flex-row h-full sm:h-24 space-y-4 sm:space-y-0">
          <div className="bg-white shadow-md rounded-l-lg px-16 py-10 w-full sm:max-w-5xl flex flex-col border sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
            <div className="flex items-center space-x-2">
              <FaMapMarkerAlt className="text-gray-500" />
              <span className="text-gray-800">Shabarimala</span>
            </div>
            <span className="text-5xl text-gray-300 font-thin hidden sm:flex">
              |
            </span>
            

            <div className="flex items-center space-x-2 cursor-pointer">
              <span className="text-gray-800">Boarding Point</span>
              <MdKeyboardArrowDown className="text-gray-500" />
            </div>
            <span className="text-5xl text-gray-300 font-thin hidden sm:flex">
              |
            </span>

            <div className="flex items-center space-x-2 cursor-pointer">
              <span className="text-gray-800">Select Date</span>
              <MdKeyboardArrowDown className="text-gray-500" />
            </div>
          </div>
          <span className="sm:rounded-r-lg bg-[#A51717] w-56 text-white items-center flex justify-center">
            Start Booking
          </span>
        </div> */}
        {/* <div className="max-w-screen-xl mx-auto px-2 pt-10">
          {trips?.length && (
            <>
              <h1 className="text-2xl font-bold text-gray-800 mb-6">
                Available Trips
              </h1>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {trips.map((trip, index) => (
                  <TripCard key={index} trip={trip} />
                ))}
              </div>
            </>
          )}
        </div> */}
        <section className="max-w-screen-xl mx-auto px-2 pt-10 ">
          <div className="">
            <h2 className="text-3xl font-bold">Who We Are</h2>
            <p className="text-gray-700 mt-4">
              In the travel and tourism sector, Vismaya Greenlines Private
              Limited has established itself by offering transport services
              within Kerala and expanding to neighboring states like Karnataka
              and Tamil Nadu. The company focuses on enhancing efficiency in
              passenger transportation, with plans to diversify into interstate
              bus services. By leveraging its expertise in logistics and
              technology, Vismaya aims to improve travel experiences, offering
              safer and more cost-effective services through monitoring systems
              and AI-based innovations. Our expansion into tourism aligns with
              India's growing market, and Our strategy includes integrating
              advanced technologies to streamline travel operations, such as
              reducing delays and improving service quality
            </p>
          </div>
        </section>

        <section className="max-w-screen-xl mx-auto px-2 pt-28">
          <div className="">
            <h2 className="text-3xl font-bold">Disclaimer</h2>
            <ul className="text-gray-700 mt-4 list-disc list-inside">
              <li>
                As per guidelines from the Travancore Devaswom Board, all
                pilgrims are requested to make a Virtual Queue reservation
                before Darshan.
              </li>
              <li>
                Virtual Queue reservation can be done in advance through the
                website:
                <a
                  href="https://www.sabarimalaonline.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  www.sabarimalaonline.org
                </a>
              </li>
              <li>
                Vismaya Greenlines Pvt Ltd is not responsible for any loss or
                any other illegal activities.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default HomeWrapper;
